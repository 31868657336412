.wrapper {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1px;
    grid-auto-rows: minmax(10px, auto);
}
.wrapper.year {
    grid-template-columns: repeat(5, 1fr);
}
.wrapper.month {
    grid-template-columns: repeat(6, 1fr);
}

.wrapper div {
    padding: 5px;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
}

.wrapper div span {
    padding: 5px;
    display: inline-block;
}
.wrapper div.header,
.wrapper div.today {
    color: #082e53;
}

.wrapper div.selected {
    background-color: #082e53;
    color: #fff
}

.calender {
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}