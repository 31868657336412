.snack-bar {
    position : fixed;
    bottom   : 15px;
    width    : 90%;
    left     : 50%;
    transform: translateX(-50%);
    animation: fadein .6s ease-in-out;
    z-index  : 99999999;
}

.snack-bar div {
    margin         : 0 auto;
    width          : fit-content;
    padding        : 10px 20px;
    background     : #323232;
    font-size      : 13px;
    text-align     : center;
    color          : #fff;
    letter-spacing : 1px;
    min-width      : 150px;
    justify-content: space-between;
}

.snack-bar button {
    border     : none;
    outline    : none;
    background : transparent;
    color      : yellow;
    margin-left: 25px;
}

@keyframes fadein {
    from {
        bottom: 0;
    }

    to {
        bottom: 15px;
    }
}

/* 
@keyframes fadeout {
    from {
        bottom: 30px;
    }
    to {
        bottom: 0;
    }
} */