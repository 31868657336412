.Pending {
    color: #0081ff;
}

.Rejected {
    color: #fb4b4b;
}

.Accepted {
    color: lightgreen;
}

.Completed {
    color: #4caf50;
}

.InProgress {
    color: orange;
}

.OutToDeliver {
    color: rgb(119, 128, 0);
}

.ReadyToPick {
    color: #7baef9;
}

.Cancelled {
    color: red;
}

.filters span.chip {
    display: inline-block;
}

.filters-popup {
    display  : none;
    animation: show .3s ease-in;
    top      : 100%
}

@keyframes show {
    0% {
        top: 100%
    }

    100% {
        top: 0;
    }
}

.filters-popup.show {
    display: block;
    top    : 0;
    height : 100vh;
}

.filter-heading {
    height  : 60px;
    position: fixed;
    width   : 100%;
    z-index : 9;
}

.filter-content {
    margin-top: 60px;
}

.filter-svg {
    width      : 25px;
    height     : 25px;
    margin-top : 11px;
    margin-left: 15px;
    float      : left;
}