input.register-input,
textarea {
  display   : block;
  width     : 100%;
  box-sizing: border-box;
  border    : 1px solid;
  margin-top: 20px;
  text-align: left;
}

.qr-powered {
  position  : fixed;
  bottom    : 1%;
  left      : 50%;
  transform : translateX(-50%);
  text-align: center;
}

.qr-powered p.power {
  font-weight: bold;
  font-size  : larger;
}

.otp {
  width : 15px;
  margin: 10px 15px;
}

.code {
  position    : absolute;
  padding-left: 10px;
  margin-top  : 11px;
}

.mobile {
  padding-left: 40px;
}