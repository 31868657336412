.loader {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #eee;
    z-index: 99999;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

.animated-background, .text-line {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #eee;
    background: linear-gradient(to right, #eee 8%, #fff 18%, #eee 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

.text-line {
    height: 10px;
    margin: 6px 0;
}
