.chip-cart {
    font-size     : 10px;
    text-transform: uppercase;
    padding       : 10px 15px;
    letter-spacing: 1px;
    border-radius : 0;
    border        : 2px solid #082e53;
    min-width     : 47px;
    transition    : all .5s ease-in;
    background    : rgba(8, 46, 84, .2);
    color         : #082e53;
    font-weight   : 700;
    letter-spacing: 1px;
}

.chip-cart:first-child {
    border-bottom-left-radius: 20px;
    border-top-left-radius   : 20px;
}

.chip-cart:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius   : 20px;
}

.chip-cart.selected {
    background: #082e53;
    color     : #fec458;
}


.empty-cart-message {
    font-size : 21px;
    text-align: center;
    position  : fixed;
    top       : 50%;
    margin    : 20px;
    transform : translateY(-50%);
}