.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background: rgba(8, 46, 83, .5);
}

.menu-container nav {
  width: 60%;
  height: 100vh;
  background: #fff;
  min-width: 230px;
}

.menu-container nav a {
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  display: block;
}

.menu-container nav a div.main-menu {
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 600;
  padding: 15px;
}

.menu-container nav a div.sub-menu {
  padding-left: 30px;
  padding-bottom: 10px;
  min-height: 20px;
}

.background-primary.p-10 {
  position: relative;
}

.background-primary.p-10 svg {
  height: 200px;
  vertical-align: middle;
}

.menu-img {
  width: 25px;
  filter: blur(0px);
  margin-right: 10px;
}

@media only screen and (min-device-width: 768px) {
  .menu-container nav {
    width: 25%;
  }
}