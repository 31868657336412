.progress {
    position     : relative;
    padding      : 0 1rem 0 4.5rem;
    margin       : 2rem 0;
    list-style   : none;
    margin-bottom: 0;
}

.progress__item {
    position         : relative;
    min-height       : 50px;
    counter-increment: list;
    padding-left     : 0.5rem;
}

.progress__item:before {
    content    : "";
    position   : absolute;
    left       : -1.6rem;
    top        : 36px;
    height     : 47%;
    width      : 2px;
    border-left: 2px solid #bfbebe;
}

.progress__item:after {
    content        : counter(list);
    position       : absolute;
    top            : 0px;
    left           : -2.5rem;
    width          : 26px;
    height         : 26px;
    border-radius  : 50%;
    background     : transparent;
    color          : #bfbebe;
    font-weight    : 700;
    font-size      : 12px;
    line-height    : 2rem;
    text-align     : center;
    border         : 2px solid;
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.progress__item:last-child:before {
    border: none;
}

.progress__item.progress__item--completed {
    color: #4caf50;
}

.progress__item.progress__item--completed:after {
    content     : "\2713";
    font-weight : 400;
    background  : #4caf50;
    border-color: #4caf50;
    color       : #ffffff;
}

.progress__item.progress__item--completed:before {
    border-color: #4caf50;
}

.progress__item.progress__item--active:after {
    background  : #fff;
    color       : #f7be16;
    border-color: #f7be16;
}

.progress__item.progress__item--active:before {
    border-color: #f7be16;
}

.progress__info {
    font-size: 15px;
    color    : #000;
    opacity  : 0.6;
}

.progress__item.progress__item--active .progress__info {
    color  : #f7be16;
    opacity: 1;
}

.progress__item.progress__item--completed .progress__info {
    color  : #4caf50;
    opacity: 1;
}

.progress__item.progress__item--rejected .progress__info,
.progress__item.progress__item--rejected:after {
    color: red;
}

.fab-order-details {
    bottom: 60px;
}

.fab-order-details>span {
    top: 50%;
}

.fab-order-details>span.spinner {
    left: 48%;
}

@media only screen and (min-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .grid.order-details {
        grid-template-columns: repeat(2, 1fr);
    }
}