.card-title {
    text-align: center;
    margin-bottom: 0;
}

.card-title-left {
    text-align: left;
}

.address-fontsize {
    font-size: 12px;
}