.business-overlay {
    clip-path: none;
    background: #fec458;
    z-index: 999999;
}

.business-overlay .qr-close {
    z-index: 9999999;
}

.business-id {
    position: absolute;
    top: 29%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 100%;
    max-width: 80%;
}

.business-id input {
    font-size: 20px;
    border-bottom: 2px solid;
    letter-spacing: 8px;
    text-transform: uppercase;
    padding: 10px 40px 10px 20px;
    color: var(--primary-color);
    width: -webkit-fill-available;
}

.business-id label {
    position: absolute;
    top: -23px;
    letter-spacing: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}

.business-id input::placeholder {
    color: var(--primary-color);
    opacity: .7;
}

.business-id .gg-spinner {
    transform: translateX(-10px);
}

.add-service-button {
    padding: 5px;
    width: auto;
    min-width: auto;
    color: var(--secondary-color);
    position: absolute;
    right: 0;
    bottom: 5px;
}

.business-id-error-msg {
    overflow-wrap: break-word;
    position: absolute;
    top: 43%;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
}

.middle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}