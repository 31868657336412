input.quantity {
  width: 25px !important;
}

.item-img {
  width  : 50px;
  padding: 10px 10px 10px 0;
}

.add-button {
  min-width : 83px;
  text-align: center;
  font-size : 13px;
  cursor: pointer;
}

.add-button span {
  padding         : 10px;
  background-color: #082e53;
  color           : #fec458;
  border-radius   : 8px;
}

.search {
  position: relative;
}

.search .gg-close {
  position : absolute;
  top      : 45%;
  transform: translateY(-50%);
  right    : 18px;
}

.search-item {
  width        : 92%;
  border-bottom: 2px solid #999;
  font-size    : 17px;
  padding-top  : 0;
  animation    : border-color 2s ease-in-out 1s;
}

.search-item:focus {
  border-color: #082e53;
}