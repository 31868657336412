input.login {
    width       : initial !important;
    font-size   : 16px;
    margin-right: 0 !important;
}

.welcome-content {
    letter-spacing: 1px;
    line-height   : 23px;
    margin-top    : 0;
}

.welcome-content p {
    margin-bottom: 0;
    margin-top   : .9em;
}

.not-subcribed .gg-close {
    position: absolute;
    right   : 10px;
}

.not-subcribed iframe {
    width        : calc(100% - 20px);
    margin-bottom: 0;
    display: block;
}