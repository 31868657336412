img {
    width: 100%;
    filter: blur(8px);
}
.small-img {
    width: 25%;
}

.loaded {
    filter: blur(0px)
}

div.loaded {
    position: relative;
    overflow: hidden
}

div.loaded:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 55%;
    background: rgba(177, 175, 175, 0.2);
    z-index: 9999;
    left: 0;
    transform: skew(-10deg);
    animation: move-right 1s infinite;
}

@keyframes move-right {
    0% {
        left: -10%
    }
    5% {
        left: 2%
    }
    10% {
        left: 10%
    }
    15% {
        left: 15%
    }
    20% {
        left: 20%
    }
    25% {
        left: 25%
    }
    30% {
        left: 30%
    }
    35% {
        left: 35%
    }
    40% {
        left: 40%
    }
    45% {
        left: 45%
    }
    50% {
        left: 50%
    }
    55% {
        left: 55%
    }
    60% {
        left: 60%
    }
    65% {
        left: 65%
    }
    70% {
        left: 70%
    }
    75% {
        left: 75%
    }
    80% {
        left: 80%
    }
    85% {
        left: 85%
    }
    90% {
        left: 90%
    }
    95% {
        left: 95%
    }
    100% {
        left: 100%
    }
}