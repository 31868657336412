a {
  text-decoration: none;
}

.wrapper-service-catalogue {
  display              : grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap             : 15px;
  gap                  : 15px;
  grid-auto-rows       : minmax(15px, auto);
  margin               : 15px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .wrapper-service-catalogue {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .wrapper-service-catalogue {
    grid-template-columns: repeat(4, 1fr);
  }
}