video {
  width     : 100vw;
  height    : 100vh;
  object-fit: fill;
}

.overlay {
  position  : fixed;
  top       : 0px;
  left      : 0;
  right     : 0;
  bottom    : 0;
  background: rgba(8, 46, 83, 70%);
  clip-path : polygon(0% 0%, 0% 100%, 15% 100%, 15% 15%, 85% 15%, 85% 65%, 15% 65%, 15% 100%, 100% 100%, 100% 0%);
}

.qr-container .qr-overlay {
  position: fixed;
  left    : 10%;
  top     : 11%;
  right   : 10%;
  bottom  : 32%;
  background: linear-gradient(to right, #fec458 4px, transparent 4px) 0 0,
    linear-gradient(to right, #fec458 4px, transparent 4px) 0 100%,
    linear-gradient(to left, #fec458 4px, transparent 4px) 100% 0,
    linear-gradient(to left, #fec458 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, #fec458 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, #fec458 4px, transparent 4px) 100% 0,
    linear-gradient(to top, #fec458 4px, transparent 4px) 0 100%,
    linear-gradient(to top, #fec458 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size  : 20px 20px;
}

p.text {
  width     : 80%;
  text-align: center;
  position  : absolute;
  bottom    : 15%;
  left      : 50%;
  transform : translateX(-50%);
  font-size : 18px;
}

.qr-close {
  color    : #fec458;
  top      : 10px;
  position : fixed;
  left    : 10px;
  padding  : 3px;
  font-size: 20px;
}

.qr-powered {
  position  : fixed;
  bottom    : 1%;
  left      : 50%;
  transform : translateX(-50%);
  text-align: center;
  color: #fff;
}

.qr-powered p.power {
  font-weight: bold;
  font-size: larger;
}