header {
    height         : 60px;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
}

.left,
.right {
    width          : 50px;
    padding        : 10px;
    text-align     : center;
    justify-content: center;
}

.right {
    margin-right: 10px;
}

.ismenu {
    width        : initial;
    padding-right: 0;
}

.ismenu .flex-coloumn {
    min-width: 50px;
}

.center {
    width         : 100%;
    padding       : 10px;
    text-transform: capitalize;
}

.cart-count {
    position     : absolute;
    top          : -6px;
    right        : -10px;
    background   : #fec458;
    color        : #082e53;
    border-radius: 50%;
    padding      : 4px 5px;
    font-size    : 10px;
    font-weight  : 700;
}

.menu {
    position     : absolute;
    right        : 45px;
    background   : #fff;
    padding      : 15px;
    z-index      : 9999999;
    border-radius: 5px;
    font-weight  : 700;
    top          : 40px;
    box-shadow   : rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px, rgb(0 0 0 / 16%) 0px 10px 36px 0px;
}

.menu::before {
    content     : "";
    position    : absolute;
    top         : 6px;
    right       : -9px;
    border-top  : 20px solid #fff;
    border-right: 20px solid transparent;
    transform   : rotate(130deg);
    z-index     : 0;
}