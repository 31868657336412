/* Credit https://css.gg/ */

.gg-chevron-right {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px
}

.gg-chevron-right::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
    right: 6px;
    top: 4px
}

.gg-chevron-down {
    box-sizing: border-box;
    position: relative;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px
}

.gg-chevron-down::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px
}

.gg-shopping-cart {
    display: block;
    box-sizing: border-box;
    position: relative;
    transform: scale(var(--ggs, 1));
    width: 20px;
    height: 21px;
    background: linear-gradient(to left, currentColor 12px, transparent 0) no-repeat -1px 6px/18px 2px, linear-gradient(to left, currentColor 12px, transparent 0) no-repeat 6px 14px/11px 2px, linear-gradient(to left, currentColor 12px, transparent 0) no-repeat 0 2px/4px 2px, radial-gradient(circle, currentColor 60%, transparent 40%) no-repeat 12px 17px/4px 4px, radial-gradient(circle, currentColor 60%, transparent 40%) no-repeat 6px 17px/4px 4px
}

.gg-shopping-cart::after,
.gg-shopping-cart::before {
    content: "";
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: 2px;
    height: 14px;
    background: currentColor;
    top: 2px;
    left: 4px;
    transform: skew(12deg)
}

.gg-shopping-cart::after {
    height: 10px;
    top: 6px;
    left: 16px;
    transform: skew(-12deg)
}

.gg-math-plus,
.gg-math-plus::after {
    display: block;
    box-sizing: border-box;
    background: currentColor;
    border-radius: 10px
}

.gg-math-plus {
    position: relative;
    padding: 14px;
    background: transparent;
    width: 30px;
    height: 32px;
}

.gg-math-plus::before {
    content: "";
    position: absolute;
    transform: scale(var(--ggs, 1));
    width: 14px;
    height: 2px;
    left: 8px;
    top: 15px;
    background: currentColor;
}

.gg-math-plus::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 14px;
    top: 9px;
    left: 14px;

}

.gg-math-minus {
    padding: 14px 9px;
}

.gg-math-minus::after {
    content: "";
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 10px;
    height: 2px;
    background: currentColor;
    border-radius: 10px
}

.inr-sign::before {
    content: "\20B9";
}

.gg-menu-left-alt {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 16px;
    height: 14px;
    transform: scale(var(--ggs, 1))
}

.gg-menu-left-alt::after,
.gg-menu-left-alt::before {
    content: "";
    position: absolute;
    box-sizing: border-box;
    display: block;
    height: 2px;
    border-radius: 4px;
    background: currentColor
}

.gg-menu-left-alt::before {
    width: 16px;
    box-shadow: 0 12px 0
}

.gg-menu-left-alt::after {
    width: 10px;
    top: 6px
}

.gg-arrow-left {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px
}

.gg-arrow-left::after,
.gg-arrow-left::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 3px
}

.gg-arrow-left::after {
    width: 8px;
    height: 8px;
    border-bottom: 2px solid;
    border-left: 2px solid;
    transform: rotate(45deg);
    bottom: 7px
}

.gg-arrow-left::before {
    width: 16px;
    height: 2px;
    bottom: 10px;
    background: currentColor
}

.gg-home {
    background: linear-gradient(to left, currentColor 5px, transparent 0) no-repeat 0 bottom/4px 2px, linear-gradient(to left, currentColor 5px, transparent 0) no-repeat right bottom/4px 2px;
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 18px;
    height: 14px;
    border: 2px solid;
    border-top: 0;
    border-bottom: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: -2px
}

.gg-home::after,
.gg-home::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute
}

.gg-home::before {
    border-top: 2px solid;
    border-left: 2px solid;
    border-top-left-radius: 4px;
    transform: rotate(45deg);
    top: -5px;
    border-radius: 3px;
    width: 14px;
    height: 14px;
    left: 0
}

.gg-home::after {
    width: 8px;
    height: 10px;
    border: 2px solid;
    border-radius: 100px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    left: 3px;
    bottom: 0
}

.gg-user {
    display: block;
    transform: scale(var(--ggs, 1));
    box-sizing: border-box;
    width: 12px;
    height: 18px
}

.gg-user::after,
.gg-user::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border: 2px solid
}

.gg-user::before {
    width: 8px;
    height: 8px;
    border-radius: 30px;
    top: 0;
    left: 2px
}

.gg-user::after {
    width: 12px;
    height: 9px;
    border-bottom: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    top: 9px
}

.gg-close {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 40px
}

.gg-close::after,
.gg-close::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 16px;
    height: 2px;
    background: currentColor;
    transform: rotate(45deg);
    border-radius: 5px;
    top: 8px;
    left: 1px
}

.gg-close::after {
    transform: rotate(-45deg)
}

.gg-shopping-bag {
    transform: scale(var(--ggs, 1))
}

.gg-shopping-bag,
.gg-shopping-bag::after {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid;
    border-radius: 3px
}

.gg-shopping-bag::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 6px;
    border-bottom-left-radius: 120px;
    border-bottom-right-radius: 120px;
    border-top: 0;
    top: 2px;
    left: 3px
}

.gg-spinner {
    transform: scale(var(--ggs, 1))
}

.gg-spinner,
.gg-spinner::after,
.gg-spinner::before {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.gg-spinner::after,
.gg-spinner::before {
    content: "";
    position: absolute;
    border-radius: 100px
}

.gg-spinner::before {
    animation: spinner 1s cubic-bezier(.6, 0, .4, 1) infinite;
    border: 3px solid transparent;
    border-top-color: currentColor
}

.gg-spinner::after {
    border: 3px solid;
    opacity: .2
}

@keyframes spinner {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(359deg)
    }
}

.gg-undo {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 14px;
    height: 14px;
    border: 2px solid;
    border-left-color: transparent;
    border-radius: 100px
}

.gg-undo::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-top: 2px solid;
    border-left: 2px solid;
    top: -3px;
    left: -1px;
    transform: rotate(-68deg)
}

.gg-qr {
    transform: scale(var(--ggs, 1))
}

.gg-qr,
.gg-qr::after {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 14px;
    height: 14px;
    box-shadow:
        -6px -6px 0 -4px,
        6px 6px 0 -4px,
        6px -6px 0 -4px,
        -6px 6px 0 -4px
}

.gg-qr::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    background: currentColor;
    box-shadow:
        0 6px 0,
        6px 6px 0,
        6px 0 0;
    top: 2px;
    left: 2px
}

.gg-image {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 20px;
    height: 16px;
    overflow: hidden;
    box-shadow: 0 0 0 2px;
    border-radius: 2px
}

.gg-image::after,
.gg-image::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border: 2px solid
}

.gg-image::after {
    transform: rotate(45deg);
    border-radius: 3px;
    width: 16px;
    height: 16px;
    top: 9px;
    left: 6px
}

.gg-image::before {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    top: 2px;
    left: 2px
}

.gg-calendar-today {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 18px;
    height: 18px;
    border: 2px solid;
    border-top: 4px solid;
    border-radius: 3px
}

.gg-calendar-today::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    background: currentColor;
    height: 4px;
    width: 4px;
    border-radius: 2px;
    right: 2px;
    bottom: 2px
}

.gg-calendar,
.gg-calendar::before {
    display: block;
    box-sizing: border-box
}

.gg-calendar {
    position: relative;
    transform: scale(var(--ggs, 1));
    width: 18px;
    height: 18px;
    border: 2px solid;
    border-top: 4px solid;
    border-radius: 3px
}

.gg-calendar::before {
    content: "";
    position: absolute;
    width: 10px;
    border-radius: 3px;
    left: 2px;
    background: currentColor;
    height: 2px;
    top: 2px
}

.gg-font-height {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    transform: scale(var(--ggs, 1));
    border-top: 2px solid;
    border-bottom: 2px solid;
    background:
        linear-gradient(to left,
            currentColor 10px,
            transparent 0) no-repeat center 8px/6px 2px
}

.gg-font-height::after,
.gg-font-height::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 2px;
    height: 10px;
    background: currentColor;
    top: 2px
}

.gg-font-height::before {
    transform: rotate(25deg);
    left: 6px
}

.gg-font-height::after {
    transform: rotate(-25deg);
    right: 6px
}

.gg-scan {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    transform: scale(var(--ggs, 1));
    background:
        linear-gradient(to left, currentColor 22px,
            transparent 0) no-repeat center/2px 22px
}

.gg-scan::after,
.gg-scan::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 14px;
    border: 2px solid;
    top: 2px
}

.gg-scan::before {
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}

.gg-scan::after {
    border-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    right: 0
}