:root {
  --font-color: #696969;
  --background-color: #eee;
  --link-color: cornflowerblue;
  --primary-color: #082e53;
  --secondary-color: #fec458;
}

/* 2 */
[data-theme="dark"] {
  --font-color: #eee;
  --background-color: #696969;
  --link-color: lightblue;
}


.flex {
  display    : flex;
  align-items: center;
}

.flex-start {
  display    : flex;
  align-items: flex-start;
}

.flex-coloumn {
  flex-direction: column;
}

.block {
  display: block;
}

.grid {
  display           : grid;
  grid-template-rows: max-content;
  grid-auto-rows    : minmax(15px, auto);
}

.main {
  justify-content: space-between;
  width          : 90%;
  margin         : auto;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.content {
  position        : fixed;
  top             : 60px;
  bottom          : 0;
  left            : 0;
  right           : 0;
  overflow-y      : auto;
  background-color: var(--background-color);
}

.footer {
  position     : fixed;
  bottom       : 0;
  left         : 0;
  right        : 0;
  padding-left : 10px;
  padding-right: 10px;
  height       : 50px;
}

body {
  background-color: var(--background-color);
  color           : var(--font-color);
  height          : 100vh;
  position        : fixed;
  width           : 100%;
}

.card {
  position     : relative;
  background   : rgb(255, 255, 255);
  border       : 1px none rgb(225, 232, 238);
  border-radius: 10px;
  box-shadow   : rgb(0 0 0 / 20%) 0px 0px 1px;
  margin       : 15px 15px;
  padding      : 15px;
  display      : block;
  box-shadow   : rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.button-border,
.button-filled {
  margin        : initial;
  padding       : 7px 15px;
  border-radius : 3px;
  min-width     : 100px;
  letter-spacing: .5px;
  font          : 18px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  border        : 2px solid;
  cursor        : pointer;
}

.button-border:disabled,
.button-filled:disabled {
  opacity: .7;
}

.button-border {
  background: transparent;
  color     : var(--primary-color);
}

.button-filled {
  color       : var(--secondary-color);
  background  : var(--primary-color);
  border-color: var(--primary-color);
}

.m-0 {
  margin: 0;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-tb-20 {
  margin-top   : 20px;
  margin-bottom: 20px;
}

.m-lr-5 {
  margin-left : 5px;
  margin-right: 5px;
}

.m-lr-auto {
  margin-left : auto;
  margin-right: auto;
}

.m-tb-auto {
  margin-bottom: auto;
  margin-top   : auto;
}

.m-10 {
  margin: 10px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-lr-10 {
  margin-left : 10px;
  margin-right: 10px;
}

.w-35 {
  width: 35%;
}

.w-90 {
  width: 90%;
}

.p-0 {
  padding: 0px;
}

.p-10 {
  padding: 10px;
}

.p-lr-10 {
  padding-left : 10px;
  padding-right: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-b-0 {
  padding-bottom: 0;
}

.font-80 {
  font-size: 80%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.border-0 {
  border: none;
}

a {
  color: inherit;
}

.font-bold {
  font-weight: bold;
}

.font-light {
  font-weight: lighter;
}

.color-white {
  color: white;
}

.color-red {
  color: red;
}

.color-primary {
  color: var(--primary-color);
}

.color-secondary {
  color: var(--secondary-color);
}

.background-white {
  background-color: white;
}

.background-primary {
  background-color: var(--primary-color);
}

.chip {
  font-size     : 12px;
  padding       : 5px 10px;
  border-radius : 15px;
  text-transform: lowercase;
  margin-right  : 0px;
  font-weight   : 600;
  background    : #cecece;
  border        : 1px solid;
  cursor        : pointer;
}

.chip.disabled {
  cursor : not-allowed;
  opacity: 0.45;
}

.selected {
  background: rgba(8, 46, 84, .2);
  color     : var(--primary-color);
}

.border-1 {
  border: 1px solid var(--primary-color);
}

ul {
  list-style: none;
}

.color-light {
  color: #6d6d6d
}

input,
textarea {
  border       : none;
  border-bottom: 1px solid;
  padding      : 10px;
  margin       : 0 0;
  text-align   : center;
  background   : none;
  outline      : none;
}

.overlay-popup {
  position       : fixed;
  top            : 0;
  bottom         : 0;
  left           : 0;
  right          : 0;
  display        : flex;
  justify-content: center;
  align-items    : center;
  background     : rgba(8, 46, 83, 0.2);
  flex-direction : column;
  width          : 100vw;
  height         : 100vh;
  z-index        : 9;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.text-capitalize {
  text-transform: capitalize;
}

.center-screen {
  position : fixed;
  top      : 50%;
  left     : 50%;
  transform: translate(-50%, -50%);
  width    : fit-content;
  padding  : 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.fab-icon {
  position     : fixed;
  bottom       : 20px;
  right        : 20px;
  background   : var(--primary-color);
  width        : 50px;
  height       : 50px;
  border-radius: 50%;
}

.fab-icon svg,
.fab-icon>span {
  position : absolute;
  top      : 57%;
  left     : 50%;
  transform: translate(-50%, -50%);
  width    : 20px;
}

.fab-icon svg {
  left: 53%;
}

.fab-icon>span.undo {
  top : 50%;
  left: 55%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    row-gap              : 10px;
  }
}

iframe {
  display: none;
}